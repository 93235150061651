
import { Component, VModel, Vue } from "vue-property-decorator";
import AppConfig from "@/config/AppConfig";

@Component({
  components: {},
})
export default class ItemsPerPage extends Vue {
  @VModel()
  protected itemsPerPage!: number;

  protected get options(): Array<number> {
    return AppConfig.DEFAULT_TABLE_PER_PAGE_OPTIONS;
  }
}

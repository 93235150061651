
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { IpAddress } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { IpAddressStoreMixin } from "@/mixins/Stores/IpAddressStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ItemsPerPage from "@/components/table/ItemsPerPage.vue";

@Component({
  components: { ItemsPerPage, DefaultTableLayout },
  mixins: [ListMixin],
})
export default class IpAddressList extends mixins(
  IpAddressStoreMixin,
  ListMixin
) {
  @Prop({ required: false, default: "BaseDataIpAddressList" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "ip_address" })
  public descriptionField!: string;

  protected get resource(): string {
    return IpAddress.resource;
  }

  protected loadList(options: ICrudOptions, force = false): any {
    return this.findAllIpAddressItemsAction(options);
  }

  public init(): void {}
}
